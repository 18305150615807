





































































































import TempValuationModel from 'truemarket-modules/src/models/api/valuations/TempValuationModel'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'
import { DateModel } from 'truemarket-modules/src/models/api/common'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import ConfirmDialog from 'primevue/confirmdialog'

@Component({
  components: {
    ConfirmDialog
  }
})
export default class TempIndex extends Vue {
  public loading = false;
  public totalRecords = 0;
  public tempValuations: TempValuationModel[] = [];
  public filters: { [key: string]: any } | null = null;
  public statuses = ['In Progress', 'Processing', 'Completed', 'Error'];
  public types = ['Commercial', 'Industrial', 'Residential', 'Retail', 'Rural'];
  public lazyParams: any = {};

  created () {
    this.initFilters()
  }

  mounted () {
    const dt = this.$refs.dt as any

    this.loading = true
    this.lazyParams = {
      first: 0,
      rows: dt.rows,
      sortField: null,
      sortOrder: null,
      filters: this.filters
    }

    this.loadLazyData()
  }

  loadLazyData () {
    this.loading = true

    Services.API.Valuations.PostTempValuationsFilter(this.lazyParams).then(data => {
      this.tempValuations = data.TempValuations
      this.totalRecords = data.TotalCount
      this.loading = false
    })
  }

  onPage (event: any) {
    this.lazyParams = event
    this.loadLazyData()
  }

  onFilter (_event: any) {
    this.lazyParams.filters = this.filters
    this.loadLazyData()
  }

  onClearFilters () {
    this.initFilters()
    this.lazyParams.filters = this.filters
    this.loadLazyData()
  }

  initFilters () {
    this.filters = {
      Status: { value: null, matchMode: FilterMatchMode.IN },
      Type: { value: null, matchMode: FilterMatchMode.IN },
      Address: { value: null, matchMode: FilterMatchMode.CONTAINS },
      Name: { value: null, matchMode: FilterMatchMode.CONTAINS },
      Phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
      'CreateDate.Value': {
        operator: FilterOperator.AND,
        constraints: [
          { value: null, matchMode: FilterMatchMode.DATE_AFTER },
          { value: null, matchMode: FilterMatchMode.DATE_BEFORE }
        ]
      }
    }
  }

  editTempValuation (row: any) {
    this.$router.push(
      {
        path: '/dashboard/admin/new_valuation',
        query: { tempValuationId: row.TempValuationId }
      })
  }

  formatDate (date: DateModel) {
    return date ? date.FormattedDate : ''
  }

  askCopyTempValuation (row: any) {
    this.$confirm.require({
      message: 'Are you sure you want to copy this valuation?',
      header: 'Copy Valuation Confirmation',
      accept: () => this.copyTempValuation(row),
      acceptLabel: 'Confirm',
      rejectLabel: 'Cancel'
    })
  }

  async copyTempValuation (row: any) {
    const request = {
      TempValuationId: row.TempValuationId
    }

    await Services.API.ValuationForm.CloneTempValuation(request).then(response => {
      this.loadLazyData()
    })
  }
}
